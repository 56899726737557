<template>
  <div class="manage-floor-plan-designs">
    <div class="container fluid">
      <div class="card">
        <h2 class="p-3 mb-2">Floor Plan Designs</h2>

        <!-- ============================ Table ============================ -->
        <vue-good-table
          mode="remote"
          @on-page-change="onPageChange"
          @on-per-page-change="onPerPageChange"
          @on-row-click="(e) => openDesignDetails(e.row)"
          :columns="designTableColumns"
          :rows="floorPlanDesigns"
          :totalRows="designTablePagination.total"
          :isLoading.sync="isTableLoading"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            perPage: 30,
            perPageDropdown: perPageOptions,
            dropdownAllowAll: false
          }"
        >
          <!-- Loading -->
          <div slot="loadingContent">
            <div class="d-flex justify-content-center">
              <spinner></spinner>
            </div>
          </div>
          <!-- Empty State -->
          <div slot="emptystate">
            <no-data message="There are no floor plan designs.">
              <p>Create one from a project floor plan.</p>
              <fd-button
                class="mt-2 main"
                @click="$router.push({ name: 'Projects' })"
              >
                Go To Project
              </fd-button>
            </no-data>
          </div>
          <!-- Table Buttons -->
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'actions'" @click.stop>
              <fd-button
                class="main bordered mr-1"
                @click="editDesign(props.row)"
              >
                <i class="fas fa-pen"></i>
              </fd-button>
              <fd-button
                class="danger bordered"
                @click="deleteDesign(props.row)"
              >
                <i class="fas fa-trash"></i>
              </fd-button>
            </span>
            <img
              v-else-if="props.column.field == 'mainP'"
              :src="$getFileURL(props.row.mainPhoto[0])"
              :alt="`${props.row.name} Main Photo`"
              class="design-main-photo"
            />
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
    <!-- ============================== Modal ============================== -->
    <modal v-model="isDesignDetailShown" fullscreen>
      <modal-content class="bg-white">
        <template #body>
          <design-details :design="designDetailToShow"></design-details>
        </template>
        <template #footer>
          <div class="d-flex justify-content-end p-2">
            <fd-button class="medium main" @click="closeDesignDetails">
              Close
            </fd-button>
          </div>
        </template>
      </modal-content>
    </modal>
    <modal v-model="isEditDesignShown">
      <edit-design
        class="card"
        :design="designToEdit"
        @close="isEditDesignShown = false"
        @submit="updateDesign"
      ></edit-design>
    </modal>
  </div>
</template>

<script>
import { floorPlanDesign as designAPI } from "@/api";
import { FloorPlanDesignModel } from "@/models";
import { numberWithCommas } from "@/utils/string";
import vueGoodTable from "@/mixins/vue-good-table/mixin";

const MAX_DESC_LENGTH = 30;

export default {
  components: {
    Spinner: () =>
      import("@/components/GlobalComponents/LoaderComponent/Spinner"),
    DesignDetails: () => import("@/components/FloorPlanDesigns/Details"),
    EditDesign: () => import("@/components/FloorPlanDesigns/EditDesign"),
    ModalContent: () =>
      import("@/components/GlobalComponents/ModalBox/ModalContent")
  },
  mixins: [vueGoodTable],
  props: {},
  data: function () {
    return {
      isTableLoading: false,

      floorPlanDesigns: [],
      designTableColumns: [
        {
          label: "",
          field: "actions"
        },
        {
          label: "Main Photo",
          field: "mainP"
        },
        {
          label: "Design Name",
          field: "name"
        },
        {
          label: "3D Panorama Link",
          field: "panoramaLink"
        },
        {
          label: "Renovation Cost/Fee",
          field: "estimatePrice",
          formatFn: (val) =>
            Number(val) === 0 ? "-" : `RM ${numberWithCommas(val)}`
        },
        {
          label: "Design Concept",
          field: "description",
          formatFn: (val) => this.formatDesc(val)
        }
      ],
      designTablePagination: {
        total: 0
      },
      tableParams: {
        page: 1,
        perPage: 30
      },

      isDesignDetailShown: false,
      designDetailToShow: {},

      isEditDesignShown: false,
      designToEdit: {}
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    init() {
      this.getDesigns();
    },
    formatDesc(val) {
      return val === ""
        ? "-"
        : val.length > MAX_DESC_LENGTH
        ? val.substring(0, MAX_DESC_LENGTH)
        : val;
    },
    editDesign(design) {
      this.isEditDesignShown = true;
      this.designToEdit = design;
    },
    openDesignDetails(design) {
      this.isDesignDetailShown = true;
      this.designDetailToShow = design;
    },
    closeDesignDetails() {
      this.isDesignDetailShown = false;
      this.designDetailToShow = {};
    },
    // ============================= Table Methods =============================
    updateParams(newProps) {
      this.tableParams = Object.assign({}, this.tableParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getDesigns();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.getDesigns();
    },
    // ============================== API Methods ==============================
    async getDesigns() {
      try {
        this.isTableLoading = true;
        let filteredParam = this.$cleanQueryParam(this.filter);

        let data = await designAPI.getDesigns({
          queries: {
            ...filteredParam,
            status: this.status
          },
          page: this.tableParams.page,
          limit: this.tableParams.perPage
        });

        this.floorPlanDesigns = this._.cloneDeep(data.data);
        this.designTablePagination = this._.cloneDeep(data.meta.pagination);

        this.isTableLoading = false;
      } catch (error) {
        this.isTableLoading = false;
        throw error;
      }
    },
    async updateDesign(payload) {
      try {
        this.$store.commit("setIsLoading", true);
        await designAPI.updateDesign(
          payload.id,
          FloorPlanDesignModel.putPayload(payload)
        );
        this.isEditDesignShown = false;
        this.$notify({
          group: "alert",
          type: "success",
          title: "Update Success",
          text: "Floor plan design has been updated successfully."
        });
        this.$store.commit("setIsLoading", false);
        this.getDesigns();
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to update floor plan design. Please try again later."
        });
      }
    },
    async deleteDesign(design) {
      let confirm = await this.$confirm({
        type: "alert",
        title: "Delete Design",
        message: `Are you sure you want to delete the design "${design.name}"? This action cannot be undone.`
      });

      if (confirm) {
        try {
          this.$store.commit("setIsLoading", true);
          await designAPI.deleteDesign(design.id);
          this.$notify({
            group: "alert",
            type: "success",
            title: "Success",
            text: `Design ${design.name} has been deleted successfully.`
          });
          this.$store.commit("setIsLoading", false);
          this.getDesigns();
        } catch (error) {
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text: "Failed to delete the design. Please try again later."
          });
        }
      }
    }
  }
};
</script>

<style lang="scss">
.manage-floor-plan-designs {
  .design-main-photo {
    border-radius: 5px;
    width: 100px;
    height: 100px;
    object-fit: cover;
  }
}
</style>
